<template>
  <div>
    <br /><br />
    <h5 class="text-center">Autenticando, espere un momento por favor</h5>
  </div>
</template>
<script>
export default {
  name: "AutoLoginPage",
  async mounted() {
    const tokenUrl = this.$route.query.token;
    this.$auth.setToken(`Bearer ${tokenUrl}`);

    await this.$axios.post("auth/add-session").then((response) => {
      const { data } = response;
      const token = this.$auth.generateToken(data.data.token_type, data.data.access_token);
      this.$auth.saveUser(data.data.user);
      this.$store.dispatch("ON_LOGIN", token);
      setTimeout(() => {
        this.$router.push({ name: "Dashboard" });
      }, 1000);
    });
  },
};
</script>
